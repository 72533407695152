var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"author") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"page__collection__share primary-btn-big js-page__collection__share\">\n            <svg class=\"page__collection__share-icon\" width=\"28\" height=\"28\" viewBox=\"0 0 28 28\" fill=\"none\"\n                xmlns=\"http://www.w3.org/2000/svg\">\n                <path\n                    d=\"M21 9.33331C22.933 9.33331 24.5 7.76631 24.5 5.83331C24.5 3.90032 22.933 2.33331 21 2.33331C19.067 2.33331 17.5 3.90032 17.5 5.83331C17.5 7.76631 19.067 9.33331 21 9.33331Z\"\n                    stroke=\"#111111\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />\n                <path\n                    d=\"M7 17.5C8.933 17.5 10.5 15.933 10.5 14C10.5 12.067 8.933 10.5 7 10.5C5.067 10.5 3.5 12.067 3.5 14C3.5 15.933 5.067 17.5 7 17.5Z\"\n                    stroke=\"#111111\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />\n                <path\n                    d=\"M21 25.6667C22.933 25.6667 24.5 24.0997 24.5 22.1667C24.5 20.2337 22.933 18.6667 21 18.6667C19.067 18.6667 17.5 20.2337 17.5 22.1667C17.5 24.0997 19.067 25.6667 21 25.6667Z\"\n                    stroke=\"#111111\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" />\n                <path d=\"M10.0215 15.7617L17.9898 20.405\" stroke=\"#111111\" stroke-width=\"2\" stroke-linecap=\"round\"\n                    stroke-linejoin=\"round\" />\n                <path d=\"M17.9782 7.59497L10.0215 12.2383\" stroke=\"#111111\" stroke-width=\"2\" stroke-linecap=\"round\"\n                    stroke-linejoin=\"round\" />\n            </svg>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"header__userbar-user-info-container js-header__userbar-user-info-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isMobile") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":16,"column":15}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"header__userbar-name-container\">\n                <div class=\"header__userbar-name\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"nickname") : stack1), depth0))
    + "</div>\n                <div class=\"header__userbar-email\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"count_collections") : stack1), depth0))
    + "</div>\n            </div>\n            <img class=\"header__avatar\" data-section=\"/user/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + "\" alt=\"\">\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"page__collection__description\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":62}}}) : helper)))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page__collection\">\n    <div class=\"page__collection__head\">\n        <div class=\"page__collection__title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":53}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"private_col") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"page__collection__body\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"films") || (depth0 != null ? lookupProperty(depth0,"films") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"films","hash":{},"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":43,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});