var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"actor-page\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"actorProfile") || (depth0 != null ? lookupProperty(depth0,"actorProfile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actorProfile","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n\n    <div class=\"actor-page__content\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"collectionBestFilms") || (depth0 != null ? lookupProperty(depth0,"collectionBestFilms") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collectionBestFilms","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});